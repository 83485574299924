<template>
  <MainLayout>
    <template v-slot:navbar-btn-wrap> </template>
    <template v-slot:default>
      <TableFilters :queries="queries">
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            <span class="title-filter">Всего:</span>{{ sourceObject.count }}
            <!--            <span>дилеров</span>-->
          </span>
        </template>
        <template v-slot:download-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4"
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
        </template>

        <template v-slot:add-button>
          <router-link to="createDealer" class="btn btn-add btn_color_green">
            Добавить дилера
          </router-link>
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
        :restore-button="isRestore"
      >
        <template v-slot:restore-td-father="{ itemRestore }">
          <button @click="clickRestore(itemRestore)" class="btn-restore btn">
            Восстановить
          </button>
        </template>
      </TableLayout>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsDealers from "@/helpers/tableCols/dealer";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "DealerPage",
  data() {
    return {
      loadingBtn: false,
      queries: query.diller,
      types: "diller",
      // type: "diller",
      isRestore: false
    };
  },
  components: { TableFilters, TableLayout, MainLayout },
  computed: {
    header() {
      return tableColsDealers;
    },
    sourceObject() {
      return this.$store.getters.getPageOrganizationList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    }
  },
  mounted() {
    if (this.urlQuery.query?.is_deleted == "true") {
      this.isRestore = true;
    } else {
      this.isRestore = false;
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query, types: this.types }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageOrganizationList", "dealer").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      if (to.query?.is_deleted == "true") {
        this.isRestore = true;
      } else {
        this.isRestore = false;
      }
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
          // type: this.type
        }
      });
      this.isDateLoaded = true;
      this.$store
        .dispatch("fetchPageOrganizationList", "dealer")
        .finally(() => {
          this.isDateLoaded = false;
        });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearOrganizationPage").then(() => next());
  },
  methods: {
    clickRestore(item) {
      this.isDateLoaded = true;
      this.$store
        .dispatch("restoreOrganization", { id: item.id, data: item })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.restore(successMessageDictionary.dealer)
          );
        })
        .then(() => {
          this.$store.dispatch("fetchPageOrganizationList", "dealer");
        })
        .finally(() => {
          this.isDateLoaded = false;
        });
    },

    downloadList() {
      this.loadingBtn = true;
      this.$store.dispatch("downloadDealerList", this.header).then(() => {
        this.loadingBtn = false;
      });
    }
  }
};
</script>

<style scoped></style>
