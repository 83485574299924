import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";

const tableColsDealers = [
  {
    text: "ID",
    value: "id",
    width: 25,
    sort: true
  },
  {
    text: "Город",
    value: "city",
    renderFunction: value =>
      `<div style="display: inline-grid"><span>${value.city_details.name}</span>
              <span style="background-color: #95c23d; border-radius: 15px; color: #fff; padding: 2px 5px">${
                dictionariesHelper.timezone[value.city_details.timezone]
              }</span></div>`
  },
  {
    text: "Название",
    value: "name",
    sort: true
  },

  {
    text: "Номер договора",
    renderFunction: value => (value.contract ? value.contract.number : " - ")
  },
  {
    text: "Дата договора",
    renderFunction: value =>
      value.contract
        ? moment(value.contract.last_modified * 1000).format("DD.MM.YYYY")
        : " - "
  },
  {
    text: "Получатель платежа",
    renderFunction: value =>
      `${dictionariesHelper.acquiringEntity[value.acquiring_entity]}`
  },
  {
    text: "Эквайринг",
    value: "acquiring_provider",
    renderFunction: value =>
      `${dictionariesHelper.acquiringProvider[value.acquiring_provider]}`,
    sort: true
  },
  {
    text: "Контроллеров",
    value: "diller_controllers_count",
    src: "/img/table_icons/tabbar__icon_5.svg",
    width: 20,
    sort: true
    // renderFunction: value => `${value.controllers_count}`
  },
  {
    text: "Объектов",
    value: "diller_objects_count",
    src: "/img/table_icons/tabbar__icon_3.svg",
    width: 20,
    sort: true
  },
  {
    text: "Компаний",
    value: "diller_clients_count",
    src: "/img/table_icons/tabbar__icon_2.svg",
    width: 20,
    sort: true
  },
  {
    text: "Пользователей",
    value: "diller_users_count",
    src: "/img/table_icons/tabbar__icon_9.svg",
    width: 20,
    sort: true
  },
  {
    text: "Дата создания",
    value: "created_at",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return moment(value.created_at * 1000).format("DD.MM.YYYY HH:mm");
    },
    sort: true
  }
];

export default tableColsDealers;
